export class Camera {
    
    public async startCamera(videoElementId: string): Promise<void> {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({video: true}).then(function (stream: MediaStream) {
                let video = document.getElementById(videoElementId) as HTMLVideoElement;
                if ("srcObject" in video) {
                    video.srcObject = stream;
                } else {
                    console.error("Your browser doesn't support srcObject property.");
                    return;
                }
                video.onloadedmetadata = function (e: Event) {
                    video.play();
                };
            });
        }
    }

    public async stopCamera(videoElementId: string): Promise<void> {
        let video = document.getElementById(videoElementId) as HTMLVideoElement;
        if (video && video.srcObject) {
            let stream = video.srcObject as MediaStream;
            let tracks = stream.getTracks();
            tracks.forEach(function (track) {
                track.stop();
            });
            video.srcObject = null;
        }
    }

    public async captureImage(videoElementId: string, canvasElementId: string): Promise<string> {
        const video = document.getElementById(videoElementId) as HTMLVideoElement;
        const canvas = document.getElementById(canvasElementId) as HTMLCanvasElement;
        const context = canvas.getContext('2d');
        if (video && canvas && context)
        {
            const width = video.videoWidth;
            const height = video.videoHeight;
            canvas.width = width;
            canvas.height = height;
            context.drawImage(video, 0, 0, width, height);
            let dataUrl = canvas.toDataURL('image/jpeg', 0.8);
            // we cant use png here as it is too big for blazor...
            if ((dataUrl.length / 1024) > 256)
            {
                console.log(`bytes: ${dataUrl.length}`)
                console.log(`kb: ${dataUrl.length / 1024}`)
                throw Error("Data URL is too big for Blazor Message Limit...")
            }
            return this.extractBase64FromDataURI(dataUrl);    
        }
        else
        {
            throw Error("Failed to capture image");
        }
    }

    private extractBase64FromDataURI(dataURI: string): string {
        const base64Prefix = /^data:.*;base64,/;
        return dataURI.replace(base64Prefix, '');
    }
    
}

(<any>window)['Camera'] = new Camera();