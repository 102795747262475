export class Helpers {
    public static GetRandomRgb() : string {
        let max = 255; // 256;
        let red = Math.floor(Math.random() * max);
        let green = Math.floor(Math.random() * max);
        let blue = Math.floor(Math.random() * max);
        return `rgb(${red}, ${green}, ${blue})`;
    }
    
    public static GetMonthNames(count: number) : string[] {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        let names: string[] = [];
        for (let i = 0; i < count; i++) {
            names.push(monthNames[i]);
        }
        return names;
    }
    
    public static GenerateColors(obj:any) : void {
        let i:number = 0;
        while (i <= obj.Data.length) {
            let r = Math.floor((Math.random() * 150) + 100); // 130 / 100
            let g = Math.floor((Math.random() * 150) + 100); // 130 / 100
            let b = Math.floor((Math.random() * 150) + 100); // 130 / 100
            obj.Colors.Background.push(`rgba(${r}, ${g}, ${b}, 0.8)`);
            obj.Colors.Border.push(`rgba(${(r)}, ${(g)}, ${(b)}, 1.0)`);
            obj.Colors.Hover.push(`rgba(${(r)}, ${(g)}, ${(b)}, 1.0)`);
            i++;
        }
    }
}