import Chart from 'chart.js/auto';
import {Helpers} from "./chart.helpers";

export class Insights {
    public statusCharts: Map<string, any> = new Map<string, any>();
    public networkSentCharts: Map<string, any> = new Map<string, any>();
    public networkReceivedCharts: Map<string, any> = new Map<string, any>();
    public countryCharts: Map<string, any> = new Map<string, any>();
    public productCharts: Map<string, any> = new Map<string, any>();
    public hourlyCharts: Map<string, any> = new Map<string, any>();

    private _charts: Map<string, Chart> = new Map<string, Chart>();

    public Status(canvasId: string, json: string): void {
        try {
            let statusChart = this.statusCharts.get(canvasId);
            this._tryDestroyChart(statusChart, canvasId);
            statusChart = this._renderPie(canvasId, statusChart, json, 'Product | Count');
            this.statusCharts.set(canvasId, statusChart);
        } catch (e) {
            console.warn(e);
        }

    }

    public NetworkSent(canvasId: string, json: string): void {
        try {
            let netSentChart = this.networkSentCharts.get(canvasId);
            this._tryDestroyChart(netSentChart, canvasId);
            netSentChart = this._renderPie(canvasId, netSentChart, json, 'Network | Count');
            this.networkSentCharts.set(canvasId, netSentChart);
        } catch (e) {
            console.warn(e);
        }

    }

    public NetworkReceived(canvasId: string, json: string): void {
        try {
            let netRecvChart = this.networkReceivedCharts.get(canvasId);
            this._tryDestroyChart(netRecvChart, canvasId);
            netRecvChart = this._renderPie(canvasId, netRecvChart, json, 'Network | Count');
            this.networkReceivedCharts.set(canvasId, netRecvChart);
        } catch (e) {
            console.warn(e);
        }

    }

    public Countries(canvasId: string, json: string): void {
        try {
            let countryChart = this.countryCharts.get(canvasId);
            this._tryDestroyChart(countryChart, canvasId);
            countryChart = this._renderPie(canvasId, countryChart, json, 'Country | Count');
            this.countryCharts.set(canvasId, countryChart);
        } catch (e) {
            console.warn(e);
        }

    }

    public Products(canvasId: string, json: string): void {
        try {
            let productChart = this.productCharts.get(canvasId);
            this._tryDestroyChart(productChart, canvasId);
            productChart = this._renderPie(canvasId, productChart, json, 'Product | Count');
            this.productCharts.set(canvasId, productChart);
        } catch (e) {
            console.warn(e);
        }

    }

    public Hourly(canvasId: string, json: string, label: string): void {
        try {
            const jsonObj = this.parsePieData(json);
            const data = {
                labels: jsonObj.Labels,
                datasets: [{
                    label: label,
                    data: jsonObj.Data,
                    fill: false,
                    backgroundColor: jsonObj.Colors.Background,
                    borderColor: jsonObj.Colors.Border,
                    hoverBackgroundColor: jsonObj.Colors.Hover,
                    tension: 0.1,
                    barPercentage: 1.0,
                }]
            };
            const config = {
                type: 'bar',
                data: data,
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                },
            };
            let hourlyChart = this.hourlyCharts.get(canvasId);
            this._tryDestroyChart(hourlyChart, canvasId);
            if (hourlyChart != null) hourlyChart.destroy();
            hourlyChart = this.renderChart(canvasId, config);
            this.hourlyCharts.set(canvasId, hourlyChart);
        } catch (e) {
            console.warn(e);
        }

    }

    protected renderChart(elementId: string, config: any): Chart|never {
        let el: HTMLCanvasElement = document.getElementById(elementId) as HTMLCanvasElement;
        let ctx = el.getContext('2d');
        return new Chart(ctx!, config);
    }

    private parsePieData(json: string): any {
        const jsonObj: any = JSON.parse(json);
        jsonObj.Colors = {
            Background: [],
            Hover: [],
            Border: []
        };

        if (jsonObj.Data.length <= 0) {
            jsonObj.Labels.push('NO-DATA');
            jsonObj.Data.push(1);
            jsonObj.Colors.Background.push(`rgb(211, 211, 211)`);
            jsonObj.Colors.Border.push(`rgb(211, 211, 211)`);
            jsonObj.Colors.Hover.push(`rgb(211, 211, 211)`);
        } else {
            // for (const key in jsonObj.Data) {
            //     jsonObj.Colors.Background.push(Helpers.GetRandomRgb());
            //     jsonObj.Colors.Border.push(Helpers.GetRandomRgb());
            // }
            Helpers.GenerateColors(jsonObj);
        }
        return jsonObj;
    }

    private _renderPie(elementId: string, chartObj: Chart, jsonData: string, label: string): Chart {
        const jsonObj = this.parsePieData(jsonData);
        const config = {
            type: 'pie', // pie | doughnut
            data: {
                labels: jsonObj.Labels,
                datasets: [{
                    label: label,
                    data: jsonObj.Data,
                    backgroundColor: jsonObj.Colors.Background,
                    borderColor: jsonObj.Colors.Border,
                    hoverBackgroundColor: jsonObj.Colors.Hover,
                    hoverOffset: 2,
                    borderWidth: 2
                }]
            },
            options: {
                responsive: false,
                maintainAspectRatio: false,
                // scales: {
                //     y: {
                //         beginAtZero: true
                //     }
                // },
                // cutout: 30
            }
        };
        if (chartObj != null) chartObj.destroy();
        return this.renderChart(elementId, config);
    }

    private _tryDestroyChart(chart: Chart, elementId: string): void {
        try {
            if (chart != null) {
                console.log('Destroy Chart: ' + elementId)
                chart.destroy();
            } else {
                console.log('nothing to destroy: ' + elementId)
            }
            console.log(chart)
        } catch (e) {
            console.warn(e);
        }
    }
}