import Chart from 'chart.js/auto';
import {Helpers} from "./chart.helpers";

export class BulkMail {
    public deliveryCharts: Map<string, any> = new Map<string, any>();
    public hourlyCharts: Map<string, any> = new Map<string, any>();
    
    public DetailsDelivery(canvasId: string, json: string): void {
        try {
            let statusChart = this.deliveryCharts.get(canvasId);
            this._tryDestroyChart(statusChart, canvasId);
            statusChart = this._renderPie(canvasId, statusChart, json, 'E-Mail Delivery');
            this.deliveryCharts.set(canvasId, statusChart);
        } catch (e) {
            console.warn(e);
        }
    }

    public OpensPerHour(canvasId: string, json: string, label: string): void {
        try {
            const jsonObj = this.parsePieData(json);
            const data = {
                labels: jsonObj.Labels,
                datasets: [{
                    label: 'E-Mail Opens Per Hour',
                    data: jsonObj.Data,
                    fill: false,
                    backgroundColor: jsonObj.Colors.Background,
                    borderColor: jsonObj.Colors.Border,
                    hoverBackgroundColor: jsonObj.Colors.Hover,
                    tension: 0.1,
                    barPercentage: 1.0,
                }]
            };
            const config = {
                type: 'bar',
                data: data,
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Opens'
                            },
                            clip: true,
                            ticks: { precision: 0 },
                        },
                        x: {
                            beginAtZero: true,
                            display: true,
                            title: {
                                display: true,
                                text: '24 Hours'
                            },
                        }
                    },
                    plugins: {
                        legend:{
                          display: false  
                        },
                        title: {
                            display: true,
                            align: 'start',
                            padding: 20,
                            text: 'E-Mail Opens Per Hour'
                        }
                    }
                },
            };
            let hourlyChart = this.hourlyCharts.get(canvasId);
            this._tryDestroyChart(hourlyChart, canvasId);
            if (hourlyChart != null) hourlyChart.destroy();
            hourlyChart = this.renderChart(canvasId, config);
            this.hourlyCharts.set(canvasId, hourlyChart);
        } catch (e) {
            console.warn(e);
        }

    }

    private parsePieData(json: string): any {
        const jsonObj: any = JSON.parse(json);
        jsonObj.Colors = {
            Background: [],
            Hover: [],
            Border: []
        };
        if (jsonObj.Data.length <= 0) {
            jsonObj.Labels.push('NO-DATA');
            jsonObj.Data.push(1);
            jsonObj.Colors.Background.push(`rgb(211, 211, 211)`);
            jsonObj.Colors.Border.push(`rgb(211, 211, 211)`);
            jsonObj.Colors.Hover.push(`rgb(211, 211, 211)`);
        } else {
            Helpers.GenerateColors(jsonObj);
        }
        return jsonObj;
    }
    protected renderChart(elementId: string, config: any): Chart|never {
        let el: HTMLCanvasElement = document.getElementById(elementId) as HTMLCanvasElement;
        let ctx = el.getContext('2d');
        return new Chart(ctx!, config);
    }
    private _renderPie(elementId: string, chartObj: Chart, jsonData: string, label: string): Chart {
        const jsonObj = this.parsePieData(jsonData);
        const config = {
            type: 'pie', // pie | doughnut
            data: {
                labels: jsonObj.Labels,
                datasets: [{
                    label: label,
                    data: jsonObj.Data,
                    backgroundColor: jsonObj.Colors.Background,
                    borderColor: jsonObj.Colors.Border,
                    hoverBackgroundColor: jsonObj.Colors.Hover,
                    hoverOffset: 2,
                    borderWidth: 2
                }]
            },
            options: {
                responsive: false,
                maintainAspectRatio: false,
                // scales: {
                //     y: {
                //         beginAtZero: true
                //     }
                // },
                // cutout: 30
            }
        };
        if (chartObj != null) chartObj.destroy();
        return this.renderChart(elementId, config);
    }
    private _tryDestroyChart(chart: Chart, elementId: string): void {
        try {
            if (chart != null) {
                console.log('Destroy Chart: ' + elementId)
                chart.destroy();
            } else {
                console.log('nothing to destroy: ' + elementId)
            }
            console.log(chart)
        } catch (e) {
            console.warn(e);
        }
    }
}