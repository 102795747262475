import Chart from 'chart.js/auto';
import {Helpers} from "./chart.helpers";

export class ShortCodes {
    public dailyChart: Map<string, any> = new Map<string, any>();

    public ReceivedDaily(canvasId: string, json: string, label: string): void {
        try {
            const jsonObj = this.parsePieData(json);
            const data = {
                labels: jsonObj.Labels,
                datasets: [{
                    label: 'Total Traffic for This Month',
                    data: jsonObj.Data,
                    fill: false,
                    backgroundColor: jsonObj.Colors.Background,
                    borderColor: jsonObj.Colors.Border,
                    hoverBackgroundColor: jsonObj.Colors.Hover,
                    tension: 0.1,
                    barPercentage: 1.0,
                }]
            };
            const config = {
                type: 'bar',
                data: data,
                options: {
                    // responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Messages Received'
                            },
                            clip: true,
                            ticks: {precision: 0},
                        },
                        x: {
                            beginAtZero: true,
                            display: true,
                            title: {
                                display: true,
                                text: 'Days of the month'
                            },
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        title: {
                            display: true,
                            align: 'start',
                            padding: 20,
                            text: 'Total Traffic for This Month'
                        }
                    }
                },
            };
            let dChart = this.dailyChart.get(canvasId);
            this._tryDestroyChart(dChart, canvasId);
            if (dChart != null) dChart.destroy();
            dChart = this.renderChart(canvasId, config);
            this.dailyChart.set(canvasId, dChart);
        } catch (e) {
            console.warn(e);
        }

    }

    private parsePieData(json: string): any {
        const jsonObj: any = JSON.parse(json);
        jsonObj.Colors = {
            Background: [],
            Hover: [],
            Border: []
        };
        if (jsonObj.Data.length <= 0) {
            jsonObj.Labels.push('NO-DATA');
            jsonObj.Data.push(1);
            jsonObj.Colors.Background.push(`rgb(211, 211, 211)`);
            jsonObj.Colors.Border.push(`rgb(211, 211, 211)`);
            jsonObj.Colors.Hover.push(`rgb(211, 211, 211)`);
        } else {
            Helpers.GenerateColors(jsonObj);
        }
        return jsonObj;
    }

    protected renderChart(elementId: string, config: any): Chart | never {
        let el: HTMLCanvasElement = document.getElementById(elementId) as HTMLCanvasElement;
        let ctx = el.getContext('2d');
        return new Chart(ctx!, config);
    }

    private _tryDestroyChart(chart: Chart, elementId: string): void {
        try {
            if (chart != null) {
                console.log('Destroy Chart: ' + elementId)
                chart.destroy();
            } else {
                console.log('nothing to destroy: ' + elementId)
            }
            console.log(chart)
        } catch (e) {
            console.warn(e);
        }
    }
}