declare var $zoho: any;

export class ZohoChat {
    public zohoChatBubble (status: string) {
        try {
            if (status == "show") {
                $zoho.salesiq.floatbutton.visible("show");
            }
            else {
               $zoho.salesiq.floatbutton.visible("hide");
            }
        } catch (e) { console.log(e); }
    }
}

(<any>window)['ZohoChat'] = new ZohoChat();
