import Chart from 'chart.js/auto';
export class LegendUtil {
    private static applicableCharts = [
        "655001-Vodacom",
        "655010-MTN",
        "655007-Cell C",
        "655002-Telkom",
        "otp-655001-Vodacom",
        "otp-655010-MTN",
        "otp-655007-Cell C",
        "otp-655002-Telkom",
        "dic-655001-Vodacom",
        "dic-655010-MTN",
        "dic-655007-Cell C",
        "dic-655002-Telkom",
        "bp-655001-Vodacom",
        "bp-655010-MTN",
        "bp-655007-Cell C",
        "bp-655002-Telkom",
        "muo-655001-Vodacom",
        "muo-655010-MTN",
        "muo-655007-Cell C",
        "muo-655002-Telkom",
        "mub-655001-Vodacom",
        "mub-655010-MTN",
        "mub-655007-Cell C",
        "mub-655002-Telkom",
    ];
    private static applicableDataIndexes = [1, 2];

    // Generate and apply custom legend and title only for the applicable charts and data items.
    public static generateAndApplyOptions(canvasId: string, config: any) {
        var isApplicable = this.applicableCharts.find(x => canvasId === x);

        if (isApplicable) {

            // This was my first attempt, but no worky
            //config.options.plugins.legend.onClick = LegendUtil.createLegendHandler();

            // Because of the legend change, the charts now need a title to identify which is which.
            config.options.plugins.title = LegendUtil.createChartTitle(config);

            // This however does the job.
            config.options.plugins.legend.labels = {};
            config.options.plugins.legend.labels.generateLabels = LegendUtil.createGenerateLabelsHandler();
        }

        return config;
    }

    // This didn't work
    private static createLegendHandler() {
        var handler = function (e: any, legend_item: any, legend: any) {
            const datasetIndex = legend_item.datasetIndex;
            const chart = legend.chart;
            const type = legend.chart.config.type;

            if (chart.isDatasetVisible(datasetIndex)) {

                //const meta = ci.getDatasetMeta(index);
                var legendItem1 = LegendUtil.createLegendItem(chart, legend_item.text, datasetIndex);
                var legendItem2 = LegendUtil.createLegendItem(chart, "new legend item", datasetIndex);

                LegendUtil.setLegendText(chart, legendItem1, 1);
                LegendUtil.setLegendText(chart, legendItem2, 2);

                chart.legend.legendItems[0] = legendItem1;
                chart.legend.legendItems[1] = legendItem2;
            }
            chart.update('none');
        };
        return handler;
    }

    private static setLegendText(chart: any, legendItem: any, dataIndex: any) {
        const dataset = chart.data.datasets[legendItem.datasetIndex];
        var value = dataset.data[dataIndex];
        var text = undefined;

        switch (dataIndex) {
            case 1:
                text = `OCEP → MNO ${value}%`;
                break;
            case 2:
                text = `Confirmed Delivery ${value}%`
                break;
            default:
                // do nothing
                break;
        }

        if (text)
            legendItem.text = text;
    }

    private static createLegendItem(chart: any, text: String, datasetIndex: Number) {
        var legendItem = LegendUtil.copy(chart.legend.legendItems[0]);

        legendItem.datasetIndex = datasetIndex;
        legendItem.hidden = false;
        legendItem.text = text;

        return legendItem;
    }

    // This works
    private static createGenerateLabelsHandler() {
        var handler = (chart: any) => {
            const datasets = chart.data.datasets;

            // Create the custom legends
            let legendLabels = [];
            for (let i = 0; i < datasets[0].data.length; i++) {
                if (LegendUtil.applicableDataIndexes.find(adi => adi === i)) {

                    const percentage = LegendUtil.calcPercentageForLegends(datasets[0].data, i);

                    let label = {
                        text: `${chart.data.labels[i]} ${percentage}%`,
                        fillStyle: datasets[0].backgroundColor[i],
                        padding: 5,
                        boxHeight: 12,
                        boxWidth: 30,
                        index: i
                    };
                    legendLabels.push(label);
                }
            }
            return legendLabels;
        };
        
        return handler;
    }

    private static calcPercentageForLegends(data: any, index: any) {
        const processed = data[0]; // VolumeProcessed
        const mnoToOcep = data[1]; // (VolumeProcessed - VolumeFailed)
        const delivered = data[2]; // VolumeDelivered
        const unconfirmed = data[3]; // VolumeUnconfirmed
        const failed = data[4]; // VolumeFailed

        if (index === 1) {
            return ((mnoToOcep / processed) * 100) | 0; // OCEP -> MNO %
        }

        if (index === 2) {
            return ((delivered / processed) * 100) | 0; // Confirmed Delivery %
        }

        return data[index];
    }

    // Set the chart title to the datasets label
    private static createChartTitle(config: any) {
        let title = LegendUtil.copy(Chart.defaults.plugins.title);
        let label: String = config.data.datasets[0].label;

        if (label.endsWith("%")) {
            label = label.split(" ").slice(0, -1).join(" ");
        }

        title.text = label;
        title.display = true;
        title.position = 'top';

        return title;
    }

    // Sort of deep copy, removing any refs if any.
    private static copy(obj: any) {
       return JSON.parse(JSON.stringify(obj));
    }

}