import Chart from 'chart.js/auto';
import { Helpers } from "./chart.helpers";

export class ShortUrl {
    public area_graph: Map<string, any> = new Map<string, any>();
    public devicePenetration_chart: Map<string, any> = new Map<string, any>();
    public browsers_chart: Map<string, any> = new Map<string, any>();

    //This only looks at a specific URL so it should never be more than one line
    public AreaChart(canvasId: string, json: string): void {
        let jsonObj = JSON.parse(json);
        let color: string = Helpers.GetRandomRgb();
        let datasets: any[] = [];

        datasets.push({
            label: jsonObj["Name"],
            data: jsonObj["Y"],
            fill: false,
            borderColor: [color],
            backgroundColor: [color],
            spanGaps: true,
            tension: 0.1,
            barPercentage: 1.0,
            barThickness: 'flex',
            maxBarThickness: 100,
        });

        const config = {
            type: 'bar',
            data: {
                labels: jsonObj["X"],
                datasets: datasets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        clip: true,
                        ticks: { precision: 0 },
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                        position: 'bottom'
                    },
                }
            }
        };

        let graph = this.area_graph.get(canvasId);
        if (graph) {
            graph.destroy();
        }
        graph = this.renderChart(canvasId, config);
        this.area_graph.set(canvasId, graph);
    }

    public PieChart(canvasId: string, json: string): void {
        let jsonObj = JSON.parse(json);
        let labels: string[] = [];
        let data: string[] = [];
        let colors: string[] = [];
        for (let i = 0; i < jsonObj.length; i++) {
            let o = jsonObj[i];
            labels.push(o.Name);
            data.push(o.Count);
            colors.push(Helpers.GetRandomRgb());
        }

        let pie = this.browsers_chart.get(canvasId);

        if (pie) {
            pie.destroy();
        }
        pie = this.renderChart(canvasId, {
            type: 'pie',
            data: {
                labels: labels,
                datasets: [{
                    data: data,
                    backgroundColor: colors,
                    hoverOffset: 4
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'right'
                    }
                }
            }
        });
        this.browsers_chart.set(canvasId, pie);
    }

    protected renderChart(elementId: string, config: any): Chart | never {
        let el: HTMLCanvasElement = document.getElementById(elementId) as HTMLCanvasElement;
        let ctx = el.getContext('2d');
        return new Chart(ctx!, config);
    }
}