declare let unlayer: any;

declare interface UnlayerExport {
    design: string;
    html: string;
}

declare interface ImageUpload {
    success: boolean,
    data: string
}

// https://dashboard.unlayer.com/projects/169512/settings/embed
export class Unlayer {

    private unlayerId: string = 'unlayer-editor';
    private notifyMethod: string = 'NotifyUnlayerReady';
    private imageMethod: string = 'UnlayerImageUploaded';
    
    // ProductId
    // BulkMail = 1
    // SmartSMS = 2

    public init(projectId: string, dotNetRef: any, baseUrl: string, productId: number): void {
        try {
            
            unlayer.init({id: this.unlayerId, projectId: projectId});
            unlayer.registerCallback('image', async (file: any, done: any) => await this.fileUploadCallback(dotNetRef, baseUrl, file, done, productId));

            const notifyMethod = this.notifyMethod;
            unlayer.addEventListener('editor:ready', function () {
                dotNetRef.invokeMethodAsync(notifyMethod);
            });
        } catch (e) {
            console.log(e);
        }
    }

    public load(projectId: string, dotNetRef: any, baseUrl: string, productId: number, design: string, mergeTags: any): void {
        try {
            const target = this.notifyMethod;
            unlayer.init({id: this.unlayerId, projectId: projectId, mergeTags: mergeTags});
            unlayer.registerCallback('image', async (file: any, done: any) => await this.fileUploadCallback(dotNetRef, baseUrl, file, done, productId));
            unlayer.addEventListener('editor:ready', function () {
                unlayer.loadDesign(JSON.parse(design));
            });
            unlayer.addEventListener('design:loaded', function (data: any) {
                // Design is loaded
                let json = data.design; // design json
                dotNetRef.invokeMethodAsync(target);
            })
        } catch (e) {
            console.log(e);
        }
    }

    public async export(): Promise<UnlayerExport | null> {
        try {
            let finalData: UnlayerExport | null = null;

            unlayer.exportHtml(function (data: any) {
                // have to honor data contract for return: UnlayerExport
                finalData = {
                    design: JSON.stringify(data.design) as string,
                    html: data.html as string
                }
            })

            while (finalData === null) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
            return finalData;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    public setMergeTags(tags: any): void {
        try {
            unlayer.setMergeTags(tags);
        } catch (e) {
            console.log(e);
        }
    }

    private async fileUploadCallback(dotNetRef: any, baseUrl: string, file: any, done: any, productId: number): Promise<void> {
        const url = `${baseUrl}/api/unlayer/image/${productId}`;
        const data = new FormData();
        data.append('file', file.attachments[0])
        data.append('url', baseUrl)
        fetch(url, {
            method: 'POST',
            headers: {'Accept': 'application/json'},
            body: data
        }).then(response => {
            if (response.status == 200) {
                return response;
            }
            throw new Error(`${response.status}: ${response.statusText}`);
        }).then(response => {
            return response.json()
        }).then(async data => {
            await dotNetRef.invokeMethodAsync(this.imageMethod, true, data);
            done({progress: 100, url: data})
        }).catch(async error => {
            await dotNetRef.invokeMethodAsync(this.imageMethod, false, error.message);
        });
    }
}

(<any>window)['Unlayer'] = new Unlayer();