export class InputLargeTextArea {
    init(callbackWrapper: any, elem: any) {
        elem.addEventListener('change', function () {
            callbackWrapper.invokeMethodAsync('NotifyChange', elem.value.length);
        });
    }

    getText(elem: any) {
        const textValue = elem.value;
        const utf8Encoder = new TextEncoder();
        return utf8Encoder.encode(textValue);
    }

    async setText(elem: any, streamRef: any) {
        const bytes = await streamRef.arrayBuffer();
        const utf8Decoder = new TextDecoder();
        elem.value = utf8Decoder.decode(bytes);
    }
    enableTextArea(elem: any, disabled: any) {
        elem.disabled = disabled;
    }
}

(<any>window)['InputLargeTextArea'] = new InputLargeTextArea();
