import Chart from 'chart.js/auto';
import { Helpers } from "./chart.helpers";

export class Omni {

    public daily_chart: Map<string, any> = new Map<string, any>();
    public hour_chart: Map<string, any> = new Map<string, any>();
    public network_chart: Map<string, any> = new Map<string, any>();

    public DailyChart(canvasId: string, json: string): void {
        let jsonObj = JSON.parse(json);

        let labels: number[] = [];
        for (let i = 1; i < 32; i++) {
            labels.push(i);
        }

        let datasets: any[] = []
        for (let i = 0; i < jsonObj.length; i++) {
            let set = jsonObj[i];
            console.log(set);

            let colors: string[] = ['#118dff'];
            datasets.push({
                label: set.label,
                data: set.data,
                backgroundColor: colors,
                borderColor: colors,
                borderWidth: 1
            });
        }

        const config = {
            type: 'bar',
            data: {
                labels: labels,
                datasets: datasets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                        position: 'bottom'
                    },
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            }
        };
        let bar = this.daily_chart.get(canvasId);
        if (bar) {
            bar.destroy();
        }
        bar = this.renderChart(canvasId, config);
        this.daily_chart.set(canvasId, bar);
    }

    public HourlyChart(canvasId: string, json: string): void {
        let jsonObj = JSON.parse(json);

        let labels: number[] = [];
        for (let i = 0; i < 24; i++) {
            labels.push(i);
        }

        let datasets: any[] = []
        for (let i = 0; i < jsonObj.length; i++) {
            let set = jsonObj[i];
            console.log(set);

            let colors: string[] = ['#118dff'];
          
            datasets.push({
                label: set.label,
                data: set.data,
                backgroundColor: colors,
                borderColor: colors,
                borderWidth: 1
            });
        }

        const config = {
            type: 'bar',
            data: {
                labels: labels,
                datasets: datasets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                        position: 'bottom'
                    },
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            }
        };
        let bar = this.hour_chart.get(canvasId);
        if (bar) {
            bar.destroy();
        }
        bar = this.renderChart(canvasId, config);
        this.hour_chart.set(canvasId, bar);
    }

    public Network(canvasId: string, json: string): void {
        let jsonObj = JSON.parse(json);
        let labels: string[] = [];
        let data: string[] = [];
        let colors: string[] = [];
        for (let i = 0; i < jsonObj.length; i++) {
            let o = jsonObj[i];
            labels.push(o.Name);
            data.push(o.Count);
            colors.push(Helpers.GetRandomRgb());
        }

       let pie = this.network_chart.get(canvasId);

        if (pie) {
            pie.destroy();
        }
        pie = this.renderChart(canvasId, {
            type: 'pie',
            data: {
                labels: labels,
                datasets: [{
                    data: data,
                    backgroundColor: colors,
                    hoverOffset: 4
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'right'
                    }
                }
            }
        });
       this.network_chart.set(canvasId, pie);
    }

    protected renderChart(elementId: string, config: any): Chart|never {
        let el: HTMLCanvasElement = document.getElementById(elementId) as HTMLCanvasElement;
        let ctx = el.getContext('2d');
        return new Chart(ctx!, config);
    }
}
